import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHeader from '../components/page-header';
import Contact from '../components/landing/Contact';

import bg from '../images/img8.jpg';

const ContactPage = () => (
  <Layout>
    <SEO title="Contacto" />
    <div className="page contact">
      <PageHeader bg={bg} />
      <div className="wrapper content">
        <h1>Contáctanos</h1>
        <p>
          Nos enorgullece ser una institución transparente. Si necesitas información específica, tienes algún comentario o sugerencia. Puedes hacerlo a través de los siguientes medios:
        </p>
        <div className="important">
          <h3>¡Importante!</h3>
          <p>
            Sabemos lo importante que es mantener la jornada de Sana Distancia en estos días cruciales por la salud de todos. Pero seguimos trabajando brindándote información o aclarando tus dudas en el correo <a href="mailto:contacto@ihb.edu.mx">contacto@ihb.edu.mx</a>.
          </p>
          <p>
            <b>Nuestro periodo de inscripciones al siguiente ciclo escolar está abierto.</b>
          </p>
        </div>
        <Contact />
      </div>
    </div>
  </Layout>
)

export default ContactPage;